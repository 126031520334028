<template>
  <div class="check">
    <el-dialog :title="title" :modal-append-to-body="false" :visible.sync="show" class="dialog">
      <div class="check-content">
        <div class="check-add">
          <el-form :inline="true" :model="data" ref="data" class="demo-form-inline">
            <div class="row infor-row">
            <div class="row-item">
              <el-form-item prop="name" :rules="[ {required: true, message: 'this information is required', trigger: 'blur', }]" label="Name">
                <el-input v-model="data.name" />
              </el-form-item>
            </div>
            <div class="row-item">
              <el-form-item prop="address" :rules="[ { required: true, message: 'this information is required', trigger: 'blur', }]" label="Address">
                <el-input v-model="data.address" />
              </el-form-item>
            </div>
            <div class="row-item">
              <el-form-item label="Phone">
                <el-input v-model="data.phone" maxlength="22"  @input="(e) => (data.phone = changePhone(e))"/>
              </el-form-item>
              </div>
            <div class="row-item">
              <el-form-item  prop="website" :rules="[ { type: 'url', message: 'Please enter the correct URL', trigger: 'blur', }, ]"  label="Website">
                <el-input v-model="data.website"></el-input>
              </el-form-item>
            </div>
            <div class="row-item">
              <el-form-item label="Cuisine">
                <el-select v-model="data.cuisines" filterable multiple placeholder>
                  <el-option v-for="item in restaurantCuisine" :key="item.id" :label="item.name" :value="item.name"></el-option>
                </el-select>
              </el-form-item>
              </div>
            <div class="row-item">
              <el-form-item label="Setting">
                <el-select v-model="data.settings"  filterable multiple placeholder>
                  <el-option v-for="item in restaurantSetting" :key="item.id" :label="item.name" :value="item.name"></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="row-item">
              <el-form-item prop="area" :rules="[ { required: true, message: 'this information is required', trigger: 'blur', }]" label="Area">
                <el-select v-model="data.area" filterable placeholder>
                  <el-option v-for="item in areas" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
            </div>
            </div>
          </el-form>
        </div>
        <div class="btn">
           <el-button class="block-button" @click="addCheck('data')">Cancel</el-button>
          <el-button class="primary-button" @click="addCheck('data')">Save</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { Minixs } from "../../../js/mixins";
import { mapActions, mapState } from "vuex";
import { apiUploadImage } from "../../../API/api";
export default {
  mixins: [Minixs],
  props: ["infor","type"],
  data() {
    return {
      data: { cuisines: [], settings: [], cover: "" },
      show: false,
      title:""
    };
  },
  computed: mapState({
    areas: (state) => state.areas,
    restaurantCuisine: (state) => state.enum.restaurantCuisine,
    restaurantSetting: (state) => state.enum.restaurantSetting,
  }),
  methods: {
    ...mapActions([
      "getAreas",
      "getrestaurantSettingList",
      "getrestaurantCuisineList",
    ]),
    uploadImages(param) {
      let form = new FormData();
      form.append("file", param.file);
      apiUploadImage(form)
        .then((res) => {
          this.data.cover = res.url;
        })
        .catch((err) => {
          this.$message("Upload failure");
        });
    },
    addCheck(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.data.restaurant_id) {
            this.$emit("update", this.data);
          } else {
            this.$emit("createRestaurant", this.data);
          }
        } else {
          return false;
        }
      });
    },
    init() {
      if (this.areas.length === 0) {
        this.getAreas();
      }
      if (this.restaurantSetting.length === 0) {
        this.getrestaurantSettingList();
      }
      if (this.restaurantCuisine.length === 0) {
        this.getrestaurantCuisineList();
      }
    },
  },
  created() {},
  watch: {
    show(val) {
      if (val === true) {
        this.init();
      }
      if(this.type==="creatType"){
        this.title="Add Restaurant"
      }else{
        this.title="Edit"
      }
      this.data = Object.assign({}, this.infor);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../Dialog.scss";
@import "../../../css/button.scss";
.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}
.check {
  text-align: left;
  .check-add {
    .add-tip {
      margin: 0;
    }
    /deep/ .el-form-item {
      margin: 0 0 10px 0;
      width: 48%;
       .el-form-item__content,
      .el-input,
      .el-select {
        width: 100%;
      }
      .el-form-item__label{
        line-height: 20px;
        font-family: "Roboto-Regular" serif;
        &::before{
          display: none;
        }
      }
    }
  }
  .row-item{
    width: calc(calc(100% - 20px) / 2);
  }
  .btn {
    text-align: right;
  }
  /deep/ .el-dialog{width: 780px;}
  @media (max-width:992px) {
    /deep/ .el-dialog{width: 90%;}
    .row-item{
      width: 100%;
    }
    .check-add/deep/ .el-form-item {width: 100%;}
    .infor-row{display: block;}
  }
}
</style>