<template>
  <div class="restaurants">
    <div>
      <Restaurants-Table ref="summary"></Restaurants-Table>
    </div>
    <div class="btn">
      <el-button @click="$refs.editShow.show = true">+ Add Restaurant</el-button>
    </div>
    <!-- <el-dialog
      title="Create"
      :modal-append-to-body="false"
      :visible.sync="show"
      width="780px"
      class="dialog"
       :type="false"
    > -->
      <Restaurant-EditDialog
        ref="editShow"
        :infor="infor"
         type="creatType"
        @createRestaurant="createRestaurant"
      ></Restaurant-EditDialog>
    <!-- </el-dialog> -->
  </div>
</template>
<script>
import RestaurantEditDialog from "./Resource/RestaurantEditDialog";
import RestaurantsTable from "./Resource/RestaurantsTable";
import { apiCreateRestaurant } from "../../API/api";
export default {
  components: { RestaurantsTable, RestaurantEditDialog },
  data() {
    return {
      show: false,
      activeName: "first",
      infor: { },
    };
  },
  methods: {
    handleClick(tab, event) {
      if (tab.name === "first") {
        this.$refs.summary.getRestaurants();
      } else if (tab.name === "second") {
        this.$refs.detail.getRestaurants();
      }
    },
    createRestaurant(val) {
      apiCreateRestaurant(val)
        .then((res) => {
          this.$refs.summary.getRestaurants();
          this.$refs.detail.getRestaurants();
        })
        .finally(() => {
          this.$refs.editShow.show = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./Dialog.scss";
.restaurants {
  border-radius: 6px;
  margin-top: 10px;
  background-color: #ffffff;
  // padding: 20px 40px 40px 40px;
  position: relative;
 
  /deep/ .el-tabs__header {
    .el-tabs__active-bar {
      background-color: #678993;
    }
    .el-tabs__item {
      font-size: 20px;
      color: #333;
    }
    .el-tabs__item:hover,
    .el-tabs__item.is-active {
      color: #678993;
    }
    .el-tabs__nav-wrap::after {
      display: none;
    }
  }
  .btn {
    position: absolute;
    top: -60px;
    right: 0px;
    z-index: 33;
    .el-button {
      color: #678993;
      background-color: #fff;
      font-size: 14px;
      width: 180px;
      font-family: "Roboto-Bold", sans-serif;
      border: none;
      text-align: right;
    }
  }
}
</style>