<template>
  <div class="summary">
    <div class="lb_table table">
      <el-table :data="this.data" v-loading="loading">
        <el-table-column
          label="ID"
          prop="restaurant_id"
          width="100"
        ></el-table-column>
        <el-table-column label="NAME" prop="name"></el-table-column>
        <el-table-column label="LASY UPDATED">
          <template slot-scope="scope">
            <div>{{ scope.row.updated_at | dateFormat }}</div>
          </template>
        </el-table-column>

        <el-table-column label="UPDATED BY" prop="user"></el-table-column>
        <el-table-column label="PUBLISH">
          <template slot-scope="scope">
            <el-switch
              @change="changeStatus($event, scope.$index, scope.row)"
              v-model="scope.row.publish"
              active-color="#678993"
              inactive-color="#ccc"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column width="52">
          <template slot-scope="scope">
            <el-tooltip
              class="item"
              effect="light"
              content="Clone"
              placement="top-start"
            >
              <i class="el-icon-copy-document" @click="clone(scope.row)"></i>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column width="52">
          <template slot-scope="scope">
            <el-tooltip
              class="item"
              effect="light"
              content="Edit"
              placement="top-start"
            >
              <i class="el-icon-edit-outline" @click="edit(scope.row)"></i>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column width="52">
          <template slot-scope="scope">
            <el-tooltip
              class="item"
              effect="light"
              content="Delete"
              placement="top-start"
            >
              <i class="el-icon-delete" @click="del(scope.row)"></i>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <Page :total="total" :pageSize="10" @number="pagesChanged"></Page>
    <Restaurant-EditDialog
      ref="editShow"
      :infor="infor"
      type="edit"
      @update="update"
    ></Restaurant-EditDialog>
  </div>
</template>
<script>
import {
  apiGetRestaurants,
  apiUploadRestaurant,
  apiDelRestaurant,
  apiCreateRestaurant,
} from "../../../API/api";
import RestaurantEditDialog from "./RestaurantEditDialog";
import Page from "../../common/Paging";
export default {
  components: { RestaurantEditDialog, Page },
  data() {
    return {
      infor: {},
      loading: false,
      data: [],
      limit: 10,
      total: 1, // 总页数
      currentPage: 1, // 当前页数
      show: false,
    };
  },
  computed: {
    offset() {
      return (this.currentPage - 1) * this.limit;
    },
    query() {
      return {
        limit: this.limit,
        offset: this.offset,
      };
    },
  },
  methods: {
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 != 0) {
        return "become_angry";
      }
      return "";
    },
    clone(val) {
      delete val.restaurant_id;
      apiCreateRestaurant(val)
        .then((res) => {
          this.$message.success("Clone successful");
          this.getRestaurants();
        })
        .catch((err) => {
          this.$$message.error("Clone failed, please clone again");
        });
    },
    edit(val) {
      this.infor = val;
      this.$refs.editShow.show = true;
    },
    del(id) {
      apiDelRestaurant(id)
        .then((res) => {
          this.$message.success("Delete succeeded");
          this.getRestaurants();
        })
        .catch((err) => {});
    },
    update(val) {
      apiUploadRestaurant(val.restaurant_id, val).then((res) => {
        this.$refs.editShow.show = false;
        this.getRestaurants();
      });
    },
    // 修改状态
    changeStatus(val, index, row) {
      let data = {
        publish: val,
      };
      apiUploadRestaurant(row.restaurant_id, data)
        .then((res) => {
          let a = this.data[index];
          a.publish = val;
          this.$set(this.data, index, a);
          this.$message.success("Set successfully");
        })
        .catch((error) => {
          this.$message.error("Setting failed");
        });
    },
    pagesChanged(number) {
      this.currentPage = number;
      this.getRestaurants();
    },
    getRestaurants() {
      this.loading = true;
      apiGetRestaurants(this.query)
        .then((res) => {
          this.data = res.results;
          this.total = res.count;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.getRestaurants();
  },
};
</script>
<style lang="scss" scoped>
@import "../../../css/table.scss";
@import "../Dialog.scss";
.summary {
  padding-bottom: 20px;
  .lb_table {
    font-size: 16px;
    /deep/ .el-table {
      td,
      th {
        text-align: left;
        padding: 15px 0;
        &:first-of-type{
          padding: 15px;
        }
      }
    }
    i {
      color: #678993;
    }
  }
}
</style>